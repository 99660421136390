/* stylelint-disable */

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  padding: 60px 12px;
  margin-bottom: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #414141;
  text-align: center;
}

.description {
  margin-top: 24px;

  div {
    font-size: 20px;
    color: #121212;

    &.pleaseUseDifferentKeyword {
      padding-top: 10px;
      font-size: 16px;
    }
  }

  // empty data
  .emptyResultDesc_content {
    .emptyResultDesc_describe {
      color: #111212;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }

    .emptyResultDesc {
      color: #202021;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    a {
      color: #1c70ee;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      &[data-disabled="true"] {
        color: #7d7d7d !important;
        cursor: default !important;
      }
    }
  }
}
