.embla {
  margin: auto;
  --slide-size: 100%;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
}

.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 32px;
}

.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 12px;
}

.embla__dot {
  appearance: none;
  display: inline-flex;
  text-decoration: none;
  pointer-events: all;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 12px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-color: #e8e8ea;
}

.embla__dot--selected {
  background-color: #3a90ff;
}
