.sectionTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: #000;
  text-align: center;

  :global {
    .coloredText {
      background: linear-gradient(62deg, #71d4ff -3.95%, #197af7 77.79%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.subtitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #4b4c4d;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .sectionTitle {
    padding: 0 16px;
    font-size: 32px;
  }

  .subtitle {
    padding: 0 16px;
  }
}
