.corporateRoot {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 24px 0 100px;
  margin: 0 auto;
}

@media screen and (max-width: 850px) {
  .corporateRoot {
    // padding: 80px 0;
    &::before {
      display: none;
    }
  }
}

.dividerRoot {
  display: flex;
  align-items: center;
  justify-content: center;

  .divider {
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1132px;
    height: 1px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
    letter-spacing: 2.88px;
    background: #eaedf3;
  }

  .dividerText {
    padding: 0 40px;
    text-transform: uppercase;
    background-color: #fff;
  }
}

@media screen and (max-width: 540px) {
  .divider {
    width: 94% !important;
    font-size: 12px;

    // margin: 80px 20px 0 20px;
    span {
      padding: 0 20px;
    }
  }
}

.corporateForMobile {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.opacityCover {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 160px;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.66) 46.94%,
    #fff 91.29%
  );
  opacity: 1;

  &:nth-of-type(1) {
    left: 0;
  }

  &:nth-of-type(2) {
    right: 0;
    transform: rotate(180deg);
  }
}
