/* stylelint-disable */
.collapse_warp {
  :global {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding-left: 0;
          padding-right: 0;

          .ant-collapse-expand-icon {
            > svg {
              transition: transform 0.3s ease;
              transform: rotate(180deg);
            }
          }
        }

        .ant-collapse-content {
          .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .ant-collapse-item-active {
        .ant-collapse-header {
          .ant-collapse-expand-icon {
            > svg {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }
}
