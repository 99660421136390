/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-class-pattern */
.footer_in_board {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 12px 16px;
  border-top: 1px solid #f6f6f6;
}

.overrideCascader {
  :global {
    .ant-select.ant-cascader {
      display: flex;
      gap: 8px;
      align-items: center;
      align-self: stretch;
      width: 100%;
      height: 36px;
      padding: 8px 12px;
      background: #f8f9fa;
      border-radius: 12px;

      .ant-select-selector {
        padding-right: 0;
        padding-left: 0;

        .ant-select-selection-item {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #3b3c3c;
        }
      }

      .ant-select-arrow {
        inset-inline-end: 8px;
      }

      .ant-select-dropdown {
        padding-top: 8px;

        .ant-cascader-menus {
          .ant-cascader-menu {
            height: unset;
            padding: 12px;
            padding-top: 0;

            .ant-cascader-menu-item {
              padding: 12px 8px 12px 16px;
            }

            .ant-cascader-menu-item.ant-cascader-menu-item-active {
              // background-color: unset;
            }
          }
        }

        .buttonReset {
          box-sizing: content-box;
          display: flex;
          flex-direction: column;
          gap: 12px;
          align-items: center;
          align-self: stretch;
          justify-content: center;
          width: 100%;
          height: 22px;
          padding: 12px 0;
          padding-bottom: 12px;
          border: unset;
          border-top: 1px solid #f6f6f6;
        }
      }

      .ant-select-arrow {
        opacity: unset;
      }
    }
  }
}

.overrideSascaderActive {
  :global {
    .ant-select.ant-cascader {
      .ant-select-arrow {
        // transform: rotate(180deg);
      }
    }
  }
}
