/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-class-pattern */

.container {
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  padding-right: 8px;
  background: #f8f9fa;
  border-radius: 12px;

  .input {
    display: flex;
    flex: 0 0 calc(100% - 20px);
    flex-direction: row;
    align-items: left;
    height: 80%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #7d7d7d;
    appearance: none;
    border: 0;
    outline: none;

    .placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #3b3c3c;
      text-align: left;
    }

    .value {
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #121212;
    }
  }
}

.searchContent {
  height: calc(100% - (env(safe-area-inset-bottom)));
  padding-bottom: env(safe-area-inset-bottom);
  overflow-y: auto;
  background: #fff;

  .selectItem:last-child {
    // margin-bottom: nv(safe-area-inset-bottom, 80px);
    margin-bottom: 30vh;
  }
}

.popContent {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;

  /* 适配底部安全区域 */
  overflow: auto;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1);
  transform: translateY(100vh);

  &.open {
    transform: translateY(0);
  }

  &.close {
    transform: translateY(100%);
  }

  .mask {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  .mainContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80vh;
    background: #fff;
    border-radius: 16px 16px 0 0;
  }
}

.itemList {
  height: fit-content;
  overflow: hidden;
  background: #fff;
  transition: height 0.3s ease-in-out;
}

.bottomContainer {
  height: calc(100% - 97px);
  padding-top: 8px;
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);

  .itemListWrapper {
    padding-bottom: 40px;
  }
}

.selectItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #515151;
  user-select: none;
  background: #fff;

  img {
    position: absolute;
    top: 12px;
    right: 20px;
  }
}

.topContainer {
  display: flex;
  width: 100%;
  height: 96px;
  background: transparent;

  .inputContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: 40px;
    padding: 0 16px;
    padding-right: 0;
    margin-top: 48px;
    margin-right: 20px;
    margin-left: 20px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #e8e8ea;
    border-radius: 20px;

    input {
      flex: 1;
      height: 80%;
      padding: 0;
      margin: 0;
      margin-left: 6px;
      font: inherit;
      font-size: 100%;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #7d7d7d;
      vertical-align: baseline;
      appearance: none;
      border: 0;
      outline: none;
    }
  }
}

.iconBox {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border: 1px solid #b8b8b8;
  border-radius: 3px;

  &.active {
    background: #2378e5 url("../../assets/checked.svg") no-repeat center center;
    border-color: #2378e5;
  }

  &.half {
    background: #2378e5 url("../../assets/half.svg") no-repeat center center;
  }
}

.highlight {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2378e5;
}

.textOverflow {
  flex: 0 0 100%;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #3b3c3c;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textOverflowHasNumber {
  flex: 0 0 calc(100% - 42px);
}

.selectLenWarp {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .selectLen {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 16px;
    padding: 0 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #1C70EE;
    text-align: center;
    background: rgba(0, 102, 255, 0.1);
    border-radius: 10px;
  }
}