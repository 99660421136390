.keyFeatureRoot {
  background: #f5f9ff; //linear-gradient(140deg, #fff 3.68%, #ebf3fe 65.05%);
  width: 100%;
  // margin: 0 auto;
  padding: 100px 120px 120px 120px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 100px;

  .backgroundShape {
    width: calc(100% + 0px);
    height: calc(100% + 0px);
    position: absolute;
    left: -100px;
    top: 0px;
    pointer-events: none;
    user-select: none;
    svg {
      position: relative;
      height: 100% !important;
    }
  }
}

@media screen and (max-width: 850px) {
  .keyFeatureRoot {
    padding: 80px 16px;
    gap: 60px;

    &::before {
      display: none;
    }
  }
}

.keyFeatureInnerRoot {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1136px;
  align-items: center;
  gap: 60px;
  position: relative;
  // overflow: hidden;
}

.keyFeatureRootRightSection {
  display: flex;
  flex-direction: column;
  // gap: 60px;
  flex: 1;
  .item {
    display: flex;
    padding: 0px 24px;
    flex-direction: row;
    gap: 16px;
    position: relative;
    cursor: pointer;

    .leftWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .iconWrapper {
        display: flex;
        width: 48px;
        height: 48px;
        border: 2px solid #d8e9ff;
        border-radius: 50%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        background-color: #2378e5;
        svg {
          width: 28px;
          height: 28px;
          path {
            fill: #fff !important;
          }
        }
      }
      .line {
        width: 2px;
        flex: 1;
        background-color: #d8e9ff;
      }
    }
    .rightWrapper {
      margin-bottom: 56px;
      display: flex;
      padding: 0 24px 20px 24px;
      flex-direction: column;
      align-items: flex-start;
      // gap: 12px;
      position: relative;

      &[data-checked="true"] {
        .backgroundCover {
          position: absolute;
          height: calc(100% + 25px);
          bottom: 0px;
          left: 0px;
          width: 100%;
          border-radius: 0px 16px 16px 16px;
          background: rgba(255, 255, 255);
          box-shadow: 0px 0px 30px 0px rgba(35, 120, 229, 0.08);
        }
      }
    }

    &:last-of-type {
      .line {
        display: none;
      }
      .rightWrapper {
        margin-bottom: 0;
      }
    }
  }
  .itemHeader {
    color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
    font-size: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    gap: 5px;
    // margin-bottom: 12px;
    svg {
      width: 1em;
      height: 1em;
    }
    &[data-checked="true"] {
      color: #2378e5;
      path {
        fill: currentColor;
      }
    }
  }
  .itemClarification {
    color: var(--neutral-color-text-05515151, var(--neutral-color-text-05515151, #7d7d7d));
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    z-index: 1;
    margin-top: 12px;
  }
}
.keyFeatureRootRightSectionImgWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.keyFeatureRootForMobile {
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px rgba(14, 24, 52, 0.05);
  overflow: hidden;

  .keyFeatureMobileCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 16px;
    border-radius: 4px;
    // background: rgba(255, 255, 255, 0.6);
    img {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
    .title {
      color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      text-align: left;

      svg {
        width: 24px;
        height: 24px;
      }
    }
    .clarification {
      color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
      font-size: 16px;
      font-weight: 400;
    }
  }
}
