/* stylelint-disable no-descending-specificity */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-class-pattern */
.autoComplete {
  width: 100%;

  :global {
    .ant-select.ant-select-outlined.ant-select-auto-complete {
      width: 100%;

      .ant-select-selector {
        padding-top: 4px;
        padding-left: 36px;
        background: unset;
        border: none;

        .ant-select-selection-placeholder {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: #000;
        }

        .ant-select-selection-search {
          .ant-select-selection-search-input {
            font-size: 16px;
          }
        }
      }
    }

    .ant-select.ant-select-outlined.ant-select-auto-complete.ant-select-focused.ant-select-single {
      .ant-select-selector {
        padding-left: 36px;
        border: none;
        box-shadow: none;
      }
    }

    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
      top: 50px !important;
    }
  }
}

.customAutoComplete {
  :global {
    .ant-select-item {
      padding: 12px !important;
    }

    .ant-select-item-option-grouped {
      padding-inline-start: 12px !important;
    }

    .yolo-autocomplete-select-dropdown {
      .rc-virtual-list {
        .rc-virtual-list-scrollbar {
          width: 4px !important;

          .rc-virtual-list-scrollbar-thumb {
            background-color: #dbdfe4 !important;
          }
        }
      }
    }
  }
}

.autoCompleteItem {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #111212;

  img {
    border-radius: 5px;
  }
}

.groupTitle {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #acaeb1;
}

.containerPc {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 56px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 70%, #1c70ee 10%);

  // background: linear-gradient(to right, rgba(255, 255, 255, 0) 70%, white 20%, #1c70ee 10%);
  backdrop-filter: blur(8px);
  border: 1.2px solid #1c70ee;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgba(0, 102, 255, 0.15);

  :global {
    .ant-select.activeLabel {
      .selectLabel,
      .selectLabelPlaceholder {
        color: #000 !important;
      }
    }

    .activeLabel {
      .selectLabel,
      .selectLabelPlaceholder {
        color: #000 !important;
      }
    }

    .ant-select.controlAntdSelect {
      &:hover {
        > .ant-select-selector {
          background: transparent !important;
          backdrop-filter: none !important;
          border-color: transparent !important;
          border-width: 0 !important;
          box-shadow: none !important;
        }
      }

      .ant-select-selector {
        box-shadow: none !important;
      }
    }
  }

  &:hover {
    box-shadow:
      0 0 0 3px rgba(28, 112, 238, 0.1),
      0 12px 24px 0 rgba(0, 102, 255, 0.15);

    :global {
      .ant-select {
        .ant-select-selector {
          .ant-select-selection-wrap {
            .ant-select-selection-placeholder {
              color: #1c70ee;
            }

            .ant-select-selection-item {
              .whereLabelWarp,
              .selectLabelPlaceholder {
                color: #1c70ee;
              }
            }
          }
        }

        .ant-select-arrow {
          > svg path {
            fill: #1c70ee;
          }
        }

        .selectLabel {
          color: #1c70ee !important;
        }
      }
    }
  }
}

.location {
  box-sizing: content-box;
  min-width: 150px;

  // max-width: 400px;
  padding-right: 20px;
  border-right: 1px solid #d9e8fc;
}

.showCount {
  padding-right: 60px;
}

.inputWrapper {
  display: flex;
  flex: 1;
  align-items: center;

  .searchButton {
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 56px;
    padding: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    /* 133.333% */
    color: #fff;
    cursor: pointer;
    background: #1c70ee;
    border-radius: 12px;
    fill: #1c70ee;

    span {
      background: transparent;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      span {
        // background: linear-gradient(
        //   270deg,
        //   #19c4eb 0%,
        //   #deff3a 28.5%,
        //   #17fdf9 47.5%,
        //   #f5f913 68%,
        //   #b7ffc6 100%
        // );
        // background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }
  }

  .input {
    display: flex;
    flex: 1;
    align-items: center;
    width: calc(100% - 40px);
    height: 54px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 80%, #fff 100%);
    border-radius: 12px;
  }

  .inputHasLocation {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 70%, #fff 30%);
  }
}

.hideLocation {
  width: 100%;
}
