/* stylelint-disable */
.switch_warp {
  display: flex;

  :global {
    .ant-switch {
      border-radius: 6px;
      background: #f4f5f6;
      height: 16px;
      width: 28px;
      min-width: 28px;

      .ant-switch-handle {
        width: 12px;
        height: 12px;

        &::before {
          border-radius: 4px;
          background: #ffffff;
          left: 0px;
          width: 12px;
          height: 12px;
        }
      }

      &.ant-switch-checked {
        border-radius: 6px;
        background: #1c70ee;

        &:hover:not(.ant-switch-disabled) {
          background: #1c70ee;
        }
      }

      &:hover:not(.ant-switch-disabled) {
        background: #f4f5f6;
      }

      &.ant-switch.ant-switch-checked {
        .ant-switch-handle {
          inset-inline-start: calc(100% - 14px);
        }
      }
    }
  }
}
