/* stylelint-disable selector-max-class */
/* stylelint-disable block-no-empty */
$main-width: 1200px;
$main-width-small: 1104px;
$main-width-large: 1344px;
$padding: 48px;

.mainWrapper {
  width: 100%;
  min-height: calc(100% - 53px);
  background-color: #fff;
}

.main {
  width: 100%;

  // min-width: $main-width + $padding * 2;
  min-height: calc(100% - 53px);
  padding: 0 $padding;

  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: SSFProDisplay !important;

  * {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: SSFProDisplay !important;
  }

  @media screen and (max-width: 1200px) {
    min-width: $main-width-small;
    margin: 0 auto;
  }

  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 100%;
    padding: 0 $padding;
  }

  @media screen and (min-width: 1440px) {
    width: $main-width-large;
    padding: 0;
    margin: 0 auto;
  }
}

// search Area
.searchArea {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .searchAreaBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 256px;
    margin: 0 auto;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
      linear-gradient(0deg, rgba(224, 242, 255, 0.4) 0%, rgba(224, 242, 255, 0.4) 100%),
      linear-gradient(0deg, #ebf3ff 0%, #ebf3ff 100%), #f8f9fa;
  }

  .searchAreaImage {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  .searchAreaImageBg {
    width: 889px;
    height: 283px;
    pointer-events: none;
  }

  .searchAreaHeader {
    position: relative;
    z-index: 3;
    margin-bottom: 20px;
    text-align: center;
  }

  .searchAreaTitle {
    position: relative;
    z-index: 2;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    color: #000;
  }

  .searchAreaSubTitle {
    margin-top: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #4b4c4d;
  }

  .searchAreaInput {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 1136px;
    height: 64px;
    padding: 0 100px;
    margin: 0 auto;
    text-align: initial;
  }

  :global {

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-cascader-menus .ant-cascader-menu {
      max-height: 320px;
    }

    .ant-select-selection-search {
      position: absolute;
      inset: 0;
      width: 100%;
    }

    input.ant-select-selection-search-input {
      background: transparent !important;
      border-color: transparent !important;
      border-width: 0;
    }
  }
}

// searchArea fixed
.searchAreaFix {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;

  // height: 88px;
  padding: 15px 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%),
    rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12.25px);
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.06);
  transition: 0.3s;
  animation: showTrans 0.25s ease-in-out both;

  .searchLogo {
    display: flex;
    flex-shrink: 0;
    margin-right: 84px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0 auto;

    .searchWrapper {
      padding: 0;
      margin: 0;
    }

    :global {
      .homeSearchPcContainer {
        margin-left: 48px;
        background: rgb(255, 255, 255);
        box-shadow: unset;
      }

      .yolo-searchArea-wrapper {
        align-self: auto;
        box-shadow: none;
      }

      .yolo-searchArea-wrapper:hover {
        box-shadow: 0 0 0 3px rgba(28, 112, 238, 0.1) !important;
      }
    }

    @media screen and (max-width: 1200px) {
      min-width: $main-width-small;
      margin: 0 auto;
    }

    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 100%;
      padding: 0 $padding;
    }

    @media screen and (min-width: 1440px) {
      width: $main-width-large;
      padding: 0;
      margin: 0 auto;
    }
  }

  .shadeheader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
  }

  .download {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // width: fit-content;
    min-width: 204px;
    height: 56px;
    padding: 0 8px 0 16px;
    margin-left: 20px;
    font-size: 14px;
    color: #136fd3;
    word-break: keep-all;
    cursor: pointer;
    background: #f4f5f6;
    border-radius: 12px;

    @media (max-width: 799px) {
      display: none;
    }

    .icon {
      float: left;
      font-size: 32px;
      line-height: 18px;
      letter-spacing: 1px;
    }

    .downloadDescribe {
      margin-left: 11px;

      h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #000;
      }

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #000;
      }
    }

    &:hover {
      background: #ebeef0;

      .popover {
        transition: transform 0.4s;
        transform: translateY(0%);
      }
    }
  }

  .downloadNoOpacity {
    background: #eef1f3;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .popover {
    width: 100%;
    height: 0;
    padding-top: 0;
    overflow: hidden;
    transition: height 0.4s;
    transition: 0.4s;

    .popoverContainer {
      height: 100%;
      padding: 0 204px 0 180px;

      @media screen and (max-width: 1200px) {
        min-width: $main-width-small;
        margin: 0 auto;
      }

      @media (min-width: 1200px) and (max-width: 1440px) {
        width: 100%;

        // padding: 0 $padding;
      }

      @media screen and (min-width: 1440px) {
        width: $main-width-large;

        // padding: 0;
        margin: 0 auto;
      }
    }

    .popoverMain {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      /* stylelint-disable-next-line max-nesting-depth */
      .code {
        position: relative;
        flex: 1;

        /* stylelint-disable-next-line max-nesting-depth */
        img {
          position: absolute;
          right: -10px;
          bottom: -116px;
          width: 293px;
          height: auto;
        }
      }

      .info {
        display: flex;
        gap: 32px;
        align-items: center;

        /* stylelint-disable-next-line max-nesting-depth */
        .qrcode {
          width: 142px;
          height: 142px;
          border-radius: 12px;

          /* stylelint-disable-next-line max-nesting-depth */
          img {
            height: 100%;
          }
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .appStore {
          display: flex;
          gap: 6px;
          align-items: center;
          margin-top: 14px;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .getApp {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          color: #111212;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .getAppTips {
          display: -webkit-box;
          width: 421px;
          margin-top: 4px;
          overflow: hidden;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          color: #111212;
          text-align: left;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes showTrans {
  0% {
    transform: translate(0, -25px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.inputPlaceholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  /* stylelint-disable selector-class-pattern */
  .inputPlaceholder_center {
    padding: 0 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  /* stylelint-enable selector-class-pattern */
}