/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-class-pattern */
.languageWrapper {
  :global {
    .ant-select {
      .ant-select-dropdown {
        .ant-cascader-panel {
          width: 100%;

          .ant-cascader-menus {
            width: 100%;

            .ant-cascader-menu {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
