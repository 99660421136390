.additionalServices {
  padding: 80px 115px 160px 115px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  position: relative;
}

.servicesWrapper {
  display: flex;
  flex-direction: row;
  gap: 48px;
  margin: 0 auto;
  max-width: 1136px;

  .servicesCard {
    display: flex;
    padding: 80px 28px 40px 28px;
    min-height: 316px;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(14, 24, 52, 0.07);
    position: relative;

    .IconWrapper {
      display: inline-block;
      position: absolute;
      top: -25px;
      left: 28px;
      height: 88px;
      height: 88px;
    }

    .title {
      color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
      font-size: 24px;
      font-weight: 500;
    }

    .content {
      color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .actionBtn {
      cursor: pointer;
      margin-top: auto;
      display: inline-flex;
      align-items: center;
      gap: 6px;
      color: var(--brand-color-052378-e-5, var(--brand-color-052378-e-5, #2378e5));
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      svg {
        transform: translate3d(0, 0, 0);
        transition: all 0.25s;
        path {
          fill: currentColor !important;
        }
      }
    }
    &:hover {
      .actionBtn {
        svg {
          transform: translate3d(5px, 0, 0);
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .additionalServices {
    padding: 64px 16px 160px 16px;
  }
  .servicesWrapper {
    flex-direction: column;
    gap: 40px;
  }
}
